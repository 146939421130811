import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const configSlice = createSlice({
  name: "config",
  initialState: {
    viewer_key: "",
    base_url: "https://competition-manager.versuz.co/",
    // base_url: "http://localhost:8080/versuz-erp/",
    id: null,
    name: "",
    table: [],
    logo: "",
    type: "",
    season: "",
    home:{
      hasBanner: false
    },
    totw_set: false,
    configLoading: true,
    configError: false,
    currentFixture: {},
    all_competitions: [],
  },
  reducers: {
    setConfigLoading: (state, action) => {
      return {
        ...state,
        configLoading: action.payload,
      };
    },
    setConfig: (state, action) => {
      return {
        ...state,
        name: action.payload.name,
        table: action.payload.table,
        logo: action.payload.logo,
        totw_set: action.payload.totw_set,
        type: action.payload.type,
      };
    },
    setCompetitions: (state, action) => {
      return {
        ...state,
        all_competitions: action.payload,
      };
    },
    setCurrentFixture: (state, action) => {
      return {
        ...state,
        currentFixture: action.payload,
      };
    },
    setKey: (state, action) => {
      return {
        ...state,
        viewer_key: action.payload,
      };
    },
  },
});

export const {
  setConfigLoading,
  setConfig,
  setCurrentFixture,
  setCompetitions,
  setKey,
} = configSlice.actions;
export default configSlice.reducer;
