import React from "react";
import logo from "../../../assets/images/versuz full logo.png"
export default function Footer() {
  return (
    <div>
      <div className=" my-[80px] text-center text-xl ">
        <p className="text-base-100 mb-3"> Proudly Sponsored By</p>
        <div className="flex flex-wrap justify-center mt-[40px] gap-[20px]">
          <img
            className="w-[60px]  md:w-[120px] h-auto bg-base-100 "
            src="https://atmos-kl.com/pub/media/amasty/shopby/option_images/NIKE_2.jpg"
            alt=""
          />
          <img
            className="w-[60px]  md:w-[120px] h-auto bg-base-100 "
            src="https://atmos-kl.com/pub/media/amasty/shopby/option_images/NIKE_2.jpg"
            alt=""
          />
          <img
            className="w-[60px]  md:w-[120px] h-auto bg-base-100 "
            src="https://atmos-kl.com/pub/media/amasty/shopby/option_images/NIKE_2.jpg"
            alt=""
          />
          <img
            className="w-[60px]  md:w-[120px] h-auto bg-base-100 "
            src="https://atmos-kl.com/pub/media/amasty/shopby/option_images/NIKE_2.jpg"
            alt=""
          />
        </div>
      </div>

      <div className="h-full py-[80px] px-[20px] md:px-[40px] bg-black mt-[80px]">
        <div className="flex flex-col max-w-[600px]">
          <img className="mx-auto md:mx-0 max-w-[200px]" src={logo} alt="" />
          <p className="text-xl font-bold text-center md:text-start font-pilat mt-3">Powered By Versuz</p>
          <p className="my-3 text-center md:text-start md:max-w-[400px]">Your all-in-one platform to host, engage, and elevate sports competitions.</p>
          <div className="flex flex-col md:flex-row md:gap-4">
          <button className="mt-4  bg-purple text-sm  rounded-full py-2 md:px-4 ">Get a site for your sports competition</button>
          <button className="mt-4   text-white text-sm  rounded-full py-2 md:px-4 border">Learn more about Versuz</button>

          </div>

        </div>
      </div>
    </div>
  );
}
