import React from 'react'

export const Stat =()=>{
    const players = ["", "", "", "",];
    return (
        <div className=''>
            <h1 className=" font-extrabold  text-base-100 text-xl ms-2 mb-2">
            Stat
        </h1>
            <div className="rounded-lg h-auto border border-gray-300">
                <div className=" rounded-t-lg h-40 bg-base-100"></div>
                {
                    players.map(p => {
                return (
                    <div className='px-[15px] py-[10px] text-base-100 flex gap-2 border border-b-gray-100'>
                    <img className='w-[40px] rounded-full ' src="" alt="" />
                    <div className="grid">
                        <span className='text-base'>Player name </span>
                        <span className='text-sm'>Team name </span>
                    </div>
                <div className='ms-auto text-lg font-bold'>X</div>
                </div>
                )

                    })
                }
            </div>

        </div>
    )
}

export default function Stats() {
    const stats = ["", "", "", "",];
  return (
    <div className='mt-[50px] md:mt-[80px] max-w-[1400px] mx-auto'>
      <h1 className="text-2xl font-extrabold  text-base-100 text-center">
            Player Stats
        </h1>

        <div className="mt-[50px] mx-[20px] lg:mx-0 grid md:grid-cols-2 lg:grid-cols-4 gap-[30px] md:gap-[16px]">
            
            {
                stats.map(stat => <Stat />)
            }
            
        </div>
        
        
        <h1 className=" mt-[80px] text-2xl font-extrabold  text-base-100 text-center">
            Team Stats
        </h1>

        <div className="mt-[50px] mx-[20px] lg:mx-0 grid md:grid-cols-2 lg:grid-cols-4 gap-[30px] md:gap-[16px]">
            
            {
                stats.map(stat => <Stat />)
            }
            
        </div>
    </div>
  )
}
