import React,{useRef,useEffect} from 'react'
import { useSelector, useDispatch } from "react-redux";
import { createBracket } from "bracketry";
import { useLocation } from "react-router-dom";

export default function Bracket() {
    const config = useSelector((state) => state.config);
    const base_url = useSelector((state) => state.config.base_url);
    let viewer_key = useSelector((state) => state.config.viewer_key);
    const elementRef = useRef(null);
    const location = useLocation();
  
    let url = `${base_url}api/get_bracket_data.php?uid=${config.season}`;
  
    const requestOptions = {
      headers: {
        viewer_key: viewer_key,
      },
    };
  
    useEffect(() => {
      let path = location.pathname.split("/");
      let id = path[1];

      console.log(`${base_url}api/get_bracket_data.php?id=${id}`)
  
      fetch(`${base_url}api/get_bracket_data.php?id=${id}`, requestOptions)
        .then((res) => res.json())
        .then((data) => {
            console.log(config)
          const wrapper = document.querySelector("#tree-wrapper-element");
          wrapper.innerHTML = "";
          const options = {
            // useClassicalLayout: true,
            // verticalScrollMode: 'buttons',
            // visibleRoundsCount: 1,
            getNationalityHTML: (player) => {
              // return `<img style="width: 30px;" src="${player.logo}">`;
              return `<img style="width: 30px;" src="${player.logo}">`;
            },
            getRoundTitleElement: (roundData, roundIndex) => {
              const el = document.createElement("div");
              el.classList.add(
                "text-sm",
                "font-bold",
                "text",
                "py-4",
                "font-pilat",
                "text-base-100"
              );
              el.innerHTML = roundData.name;
              return el;
            },
          };
  
          createBracket(data, wrapper, options);
        })
        .catch((err) => {
          alert("Error getting teams");
          // navigate("/");
        });
    }, []);
    return (
        <div>
<h1 className="text-2xl font-extrabold mb-5 text-base-100 text-center">
          Bracket
        </h1>
            <div className="flex justify-center mt-4 w-full">
                <div id="tree-wrapper-element" className="mx-auto overflow-scroll"></div>
            </div>
        </div>
    );
}


