import React, { useState } from "react";
import admin from "../../../assets/images/admin-2.png"
import sportfolio from "../../../assets/images/portfolio.png"
import data from "../../../assets/images/data.png"

function Solution({ title, body,src }) {
  return (
    <div className="bg-base-100 rounded-[20px] p-[20px] mx-[20px] lg:mx-[0px] md:mx-[20px]  ">
      <img src={src} alt="" className=" bg-purple-100 rounded-[20px] w-full mb-5 h-[200px] " />
      <p className="text-purple-100 mb-[10px] font-bold text-xl text-center">{title}</p>
      <p className="text-purple-100   text-justify lg:text-center ">{body}</p>
    <div className=" flex">
      <a href="" className=" mx-auto btn text-white rounded-full  mt-[25px] px-[20px] py-[5px] bg-purple font-bold"> Learn more</a>
    </div>
    </div>
  );
}

export default function Solutions() {
  const [solutions, setSolutions] = useState([
    {
      title: "Collaborative Admin Control",
      body: "Our intuitive dashboard supports multiple users, improves real time management core aspects and  tracking of key metrics ",
      src : admin
      
    },
    {
      title: "Sportfolio",
      body: "Showcase your team’s or organization’s past performances, key achievements, and  growth journey with our comprehensive Sportfolio feature. ",
      src : sportfolio
    },
    {
      title: "Data Management  & Analytics",
      body: "Get on demand access to valuable data insight, track and analyze key metrics to measure success and optimize for future growth.",
      src : data
    },
  ]);
  return (
    <section
      data-theme="versuz"
      className="solutions bg-purple-100 rounded-[40px] px-[20px] md:px-[50px] py-[80px] max-w-[1400px] mx-auto mt-[100px] text-base-100 "
    >
      <h1 className="text-3xl text-center font-semibold hidden md:block">
        {" "}
        Powerful Solutions for <br />{" "}
        <span className="text-purple">Mordern</span> Sports Management
      </h1>
      <h1 className="text-3xl text-center font-semibold md:hidden">
        Explore  <span className="text-purple">Mordern</span> Sports Management
         Solutions  <br />{" "}
      </h1>

      <p className="text-base-100 max-w-[606px] text-cemter mx-auto mt-[18px]">
        Streamline every aspect of your competition—from team registrations and
        match scheduling to real-time score updates and automated notifications.{" "}
      </p>

      <div className="grid md:grid-cols-2 lg:grid-cols-3  mt-[50px] gap-[22px]">
        {solutions.map((sol, index) => (
          <Solution key={index} title={sol.title} body={sol.body} src={sol.src } />
        ))}
      </div>
    </section>
  );
}
