import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

import { NavLink } from "react-router-dom";
import Loading from "./components/Loading";

const Team = ({ data }) => {
  const location = useLocation();
  const base_url = useSelector((state) => state.config.base_url);

  let path = location.pathname.split("/");
  let url = path[1];

  return (
    <NavLink to={`/${url}/team/${data.id}`}>
      <div className=" p-[20px] flex gap-[20px] bg-base-100">
        <img
          className="w-[60px] aspect-square self-center rounded-full "
          // src="https://upload.wikimedia.org/wikipedia/en/thumb/7/7a/Manchester_United_FC_crest.svg/1200px-Manchester_United_FC_crest.svg.png"
          src={`${base_url}uploads/${data.logo}`}
          alt=""
        />
        <div className="self-center">
          <p className="text-xl  font-bold">{data.name}</p>
          <p className="text-sm ">{data.name}</p>
        </div>
      </div>
    </NavLink>
  );
};

export default function Teams() {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPage, setcurrentPage] = useState("teams");
  const [teams, setTeams] = useState([]);

  const [loading, setLoading] = useState(true);

  const config = useSelector((state) => state.config);
  const base_url = useSelector((state) => state.config.base_url);
  let viewer_key = useSelector((state) => state.config.viewer_key);

  useEffect(() => {
    let path = location.pathname.split("/");
    let url = path[1];

    const requestOptions = {
      headers: {
        viewer_key: viewer_key,
      },
    };

    console.log(config);

    if (config && viewer_key) {
      fetch(`${base_url}api/get_teams.php?id=${url}`, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setTeams(data.teams);
          setLoading(false)
        })
        .catch((err) => {
          alert("Error getting teams");
          navigate("/");
        });
    }
  }, []);

  useEffect(() => {
    const split = location.pathname.split("/");
    setcurrentPage(split[2]);
  });

  return (
    <>
    {
      loading ? <Loading text={"Loading Teams"} />:

      <div className="mt-[50px] lg:mt-[120px]">
        <h1 className="text-2xl font-extrabold mb-5 text-base-100 text-center">
          Teams{" "}
        </h1>
        <div className="grid  md:grid-cols-2 lg:grid-cols-3 gap-[30px] max-w-[1200px] mx-auto ">
          {teams.map((team) => (
            <Team data={team} />
          ))}
        </div>
      </div>
    }
    </>
  );
}
