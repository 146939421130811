import React from 'react'

export default function Banner() {
  return (
    <div className="aspect-[8/3] border border-black max-w-[883px] mx-auto mt-[50px]  bg-[url('https://media.cnn.com/api/v1/images/stellar/prod/220522185457-man-city-epl-trophy-lift-2022.jpg?c=16x9&q=h_833,w_1480,c_fill')] bg-center bg-contain  flex">
        <div className="w-full h-full bg-[rgba(12,11,30,.75)]   flex justify-center shadow-lg ">
          <div className="self-center">
            <p className="text-center  text-xl md:text-3xl font-bold mt-6 text-white ">
              Main Text Here
            </p>
            <p className="max-w-[450px] text-center text-sm">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta
              odio alias officia nulla dicta, in nemo dolor saepe voluptas modi.
            </p>
          </div>
        </div>
      </div>
  )
}
