import React, { useState } from "react";
import match from "../../../assets/images/match-center.png"
import engagement from "../../../assets/images/engagement.png"
import seo from "../../../assets/images/seo.png"

function Engagement({ title, body,src }) {
  return (
    <div className="bg-purple-300 rounded-[20px] mx-[20px] md:mx-[20px] lg:mx-[0px] p-[20px] border-white">
      <img src={src} alt="" className="border-4 shadow-lg  border-base-100 w-full mb-5 h-[200px] rounded-[20px]" />
      <p className="text-base-100 mb-[10px] font-bold text-xl text-center">{title}</p>
      <p className="text-base-100  text-justify lg:text-center">{body}</p>
    <div className=" flex">
      <a href="" className=" mx-auto btn bg-transparent text-base-100 rounded-full  mt-[25px] px-[20px] py-[5px] border-base-100 border-2 font-bold"> Learn more</a>
    </div>
    </div>
  );
}

export default function Engagements() {
  const [solutions, setSolutions] = useState([
    {
      title: "Match Center",
      body: "Customizable Websites and widgets, that keep fans up to date, making it easy to follow the action and stay engaged with every game.",
      src: match
    },
    {
      title: "Interactive Feedback Features",
      body: "Create custom polls, ratings, and feedback forms to keep audience engagements strong, while collecting valuable insights in real time",
      src: engagement
    },
    {
      title: "Search Optimized Pages",
      body: "Web pages and widgets are strategically optimized for search, making it easier for existing and new audiences to find you online",
      src: seo
    }
  ]);
  return (
    <section
      data-theme="versuz"
      className="solutions  rounded-[40px] px-[20px] md:px-[50px] py-[80px] max-w-[1400px] mx-auto mt-[100px] text-base-100 "
    >
      <h1 className="text-3xl text-center font-semibold text-purple-100">
        {" "}
        Innovative Tools for   <br />{" "}
        <span className="text-tomato">Enhanced</span> Fan Engagement
      </h1>

      <p className="text-purple-100 max-w-[606px] text-cemter mx-auto mt-[18px]">
        Streamline every aspect of your competition—from team registrations and
        match scheduling to real-time score updates and automated notifications.{" "}
      </p>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 mt-[50px] gap-[22px]">
        {solutions.map((sol, index) => (
          <Engagement key={index} title={sol.title} body={sol.body} src={sol.src} />
        ))}
      </div>
    </section>
  );
}
