import { configureStore } from "@reduxjs/toolkit";
import configReducer from "./slices/configSlice";
import dataReducer from "./slices/dataSlice";


export default configureStore({
  reducer: {
    config: configReducer,
    data: dataReducer,
  },
});
