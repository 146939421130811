import React from "react";
import Layout from "./components/Layout";
import { Outlet } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import Bracket from "./components/Bracket";

import Banner from "./components/Banner";
import LeagueTable from "./components/LeagueTable";
import Mixed from "./components/Mixed"

export default function Home() {
  const config = useSelector((state) => state.config);
  return (
    <div className="bg-white">
      {config.home.hasBanner && <Banner />}

      <div className=" max-w-[1200px] mx-auto mt-[50px] md:mt-[80px]">
        

        {config.type == "League" && <LeagueTable />}
        {config.type == undefined && <LeagueTable />}
        {config.type == "" && <LeagueTable />}
        {config.type == "Cup" && <Bracket />}
        {config.type == "Mixed" && <Mixed />}
        
      </div>
    </div>
  );
}
