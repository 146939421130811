import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";

const Fixture = (props) => {
  const location = useLocation();

  const { data, handleClick } = props;
  const [homeGoals, setHomeGoals] = useState(0);
  const [awayGoals, setAwayGoals] = useState(0);
  const base_url = useSelector((state) => state.config.base_url);

  let path = location.pathname.split("/");
  let url = path[1];

  useEffect(() => {
    if (data.home_team) {
      let _awayGoals = data.away_team.goals.length;
      let _homeGoals = data.home_team.goals.length;

      data.home_team.goals.forEach((goal) => {
        if (goal.assist == "own_goal") {
          _homeGoals -= 1;
          _awayGoals += 1;
        }
      });

      data.away_team.goals.forEach((goal) => {
        if (goal.assist == "own_goal") {
          _homeGoals += 1;
          _awayGoals -= 1;
        }
      });

      setAwayGoals(_awayGoals);
      setHomeGoals(_homeGoals);
    }
  }, [data]);

  return (
    <NavLink to={`/${url}/fixture/${data.id}`}>
      <div>
        <div className=" text-center mb-1 text-gray-600"> {data.date}</div>
        <div className="bg-gray-100 flex justify-center gap-1 h-[40px] md:h-[60px]">
          <div className="flex justify-end flex-1">
            <div className="self-center  h-full flex">
              <span className="text-sm md:text-xl  text-base-100 font-bold px-[0px] self-center">
                {data.home_team.name}
              </span>
              <img
                src={`${base_url}uploads/${data.home_team.logo}`} 
                alt=""
                className="w-[20px] md:w-[40px] shadow-lg border rounded-full aspect-square self-center mx-[10px] md:mx-[40px]"
              />
            </div>
          </div>
          <div className="flex gap-2 ">
            <div className="bg-base-100 w-[40px] md:w-[60px]  aspect-square text-xl md:text-2xl font-bold flex justify-center">
              <span className="self-center text-[#eec488]">{homeGoals}</span>
            </div>

            <div className="bg-base-100 w-[40px] md:w-[60px]  aspect-square text-xl md:text-2xl font-bold flex justify-center">
              <span className="self-center text-[#eec488]">{awayGoals}</span>
            </div>
          </div>
          <div className="flex flex-row-reverse justify-end flex-1">
            <div className="self-center  h-full flex flex-row-reverse ">
              <span className="text-sm md:text-xl  text-base-100 font-bold px-[0px] self-center">
                {data.away_team.name}
              </span>
              <img
                src={`${base_url}uploads/${data.away_team.logo}`} 
                alt=""
                className="w-[20px] md:w-[40px] shadow-lg border rounded-full aspect-square self-center mx-[10px] md:mx-[40px]"
              />
            </div>
          </div>
          :
        </div>
      </div>
    </NavLink>
  );
};

export default Fixture;
