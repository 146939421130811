import React from 'react'

export default function Loading({text}) {
  return (
    <div className="min-h-[400px] bg-white flex flex-col justify-center gap-3">
          <span className=" self-center loading loading-dots loading-lg bg-base-100"></span>
          <span className="text-base-100 self-center">{text}</span>
        </div>
  )
}
