import React, { useState } from "react";
import Nav from "./components/Nav";
import Hero from "./components/Hero";
import Solutions from "./components/Solutions";
import Engagements from "./components/Engagement";
import Integrate from "./components/Integrate";
import Footer from "./components/Footer";




export default function LandingPage() {
  
  return (
    <div data-theme="versuz" className="h-full relative">
      <Nav />

      <Hero />

      <Solutions />

      <Engagements />

      <Integrate />

      <Footer />
      
    </div>
  );
}
