import React from 'react'

const Stat =()=>{
    return(
        <div className="col-span-2">
                    <p className="border p-2 px-3 rounded-t-xl bg-base-100 text-white">
                        Defence
                    </p>
                    <p className="border p-2 px-3  text-base-100 ">
                        Defence
                    </p><p className="border p-2 px-3  text-base-100 ">
                        Defence
                    </p><p className="border p-2 px-3  text-base-100 ">
                        Defence
                    </p><p className="border p-2 px-3  text-base-100 ">
                        Defence
                    </p>
                </div>
    )
}

export default function PlayerDetails() {
  return (
    <div className="mt-[50px] md:mt-[80px] max-w-[1200px] mx-auto md:grid grid-cols-6 gap-4">

        <div className="bg-gray-100 p-6  col-span-2 rounded-lg shadow-lg">
                
            <img className='w-full max-w-full aspect-square' src="" alt="" />
            <div className=' w-full flex flex-col justify-start gap-4'>

                <div className='flex justify-between mt-3'>
                    <p className='text-base-100 font-bold'>
                        <span className="text-2xl">First Name</span> 
                        <br />
                        <span className="text-4xl">Last Name</span>
                  </p>

                  {/* <p className="text-5xl text-base-100 font-extrabold">
                    37
                  </p> */}
                </div>
                <div>
                    <p className='text-xl text-base-100'>Position: Defender</p>
                </div>

                <div className=' mt-1'>
                    <p className='self-center text-xl text-base-100 mb-2'>Socials</p>
                    <div className='flex gap-3 '>
                        <img className='w-[30px] aspect-square rounded-full bg-base-100' src="" alt="" />
                        <img className='w-[30px] aspect-square rounded-full bg-base-100' src="" alt="" />
                        <img className='w-[30px] aspect-square rounded-full bg-base-100' src="" alt="" />
                        <img className='w-[30px] aspect-square rounded-full bg-base-100' src="" alt="" />

                    </div>
                </div>


            </div>

        </div>

        <div className=' col-span-4'>
            <div className=' my-5 md:my-0 grid grid-cols-2  md:grid-cols-4 gap-2' >
                <div className="border p-3 rounded-lg">
                    <p>Stat</p>
                    <p className="text-4xl font-bold">10</p>
                </div>
                <div className="border p-3 rounded-lg">
                    <p>Stat</p>
                    <p className="text-4xl font-bold">10</p>
                </div>
                <div className="border p-3 rounded-lg">
                    <p>Stat</p>
                    <p className="text-4xl font-bold">10</p>
                </div>
                <div className="border p-3 rounded-lg">
                    <p>Stat</p>
                    <p className="text-4xl font-bold">10</p>
                </div>

            </div>

            <div className='grid md:grid-cols-4 p-2 mt-3 gap-2' >
                <Stat />
                <Stat />
                <Stat />
                <Stat />
                
                
            </div>

        </div>

      
    </div>
  )
}
