import React, { useState } from "react";
import Fixtures from "./Fixtures";
import { Stat } from "./Stats";
import { NavLink } from "react-router-dom";
const Members = () => {
  return (
    <div className="mt-[50px]">
      <h1 className="text-2xl font-extrabold mb-5 text-base-100 text-start ">Team Members </h1>

      <div className="mt-4">
      <h1 className="text-xl text-base-100 font-bold">Coaches</h1>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4  mt-3 gap-[20px] max-w-[1200px]">
          <NavLink to="/123/player/1234">
          <div>
            <img className="border w-full aspect-square" src="" alt="" />
            <p className="text-base-100 text-sm mt-3">Position</p>
            <p className="text-base-100 text-md font-bold">Frist Name</p>
            <p className="text-base-100 text-xl font-black">Frist Name</p>
          </div>

          </NavLink>
          <div>
            <img className="border w-full aspect-square" src="" alt="" />
            <p className="text-base-100 text-sm mt-3">Position</p>
            <p className="text-base-100 text-md font-bold">Frist Name</p>
            <p className="text-base-100 text-xl font-black">Frist Name</p>
          </div>
        </div>
      </div>
    </div>
  );
};





const Stats = () => {
  const stats = ["", "", "", "",];

  return (
    <div className="mt-[50px]">
        <h1 className="text-2xl font-extrabold mb-5 text-base-100 text-start">Team Statistics </h1>

        <div className="mt-4 grid grid-cols-2 md:grid-cols-4  gap-2">
          <div className="border p-3 rounded-md">
            <p className=" text-center text-base text-base-100 font-bold mb-1">Games Played</p>
            <h1 className="text-center text-4xl font-black">10</h1>
          </div>
          <div className="border p-3 rounded-md">
            <p className=" text-center text-base text-base-100 font-bold mb-1">Won</p>
            <h1 className="text-center text-4xl font-black">10</h1>
          </div>
          <div className="border p-3 rounded-md">
            <p className=" text-center text-base text-base-100 font-bold mb-1">Lost</p>
            <h1 className="text-center text-4xl font-black">10</h1>
          </div>
          


        </div>

      <div className="mt-[50px]">

          <h1 className="text-2xl font-extrabold  text-base-100 text-start">
              Player Statistics
          </h1>

          <div className="mt-4 mx-[20px] lg:mx-0 grid md:grid-cols-2 lg:grid-cols-4 gap-[30px] md:gap-[16px]">
              
              {
                  stats.map(stat => <Stat />)
              }
              
          </div>

      </div>

        

        
      
      </div>
);
};



export default function TeamDetails() {
  const [view, setView] = useState("members");
  const handleChange =(e)=>{
    const {view} = e.target.dataset

    setView(view)
  }
  return (
    <div className="mt-[50px] md:mt-[80px] max-w-[1200px] mx-auto ">
      <div className="bg-gray-100 p-[10px] py-[30px] flex flex-col justify-center">
        <img
          className="w-[200px] aspect-square rounded-full self-center border shadow-lg"
          src=""
          alt=""
        />

        <h1 className="text-2xl font-extrabold mt-[20px] text-base-100 text-center">
          Team Details
        </h1>
        <p className="text-center text-sm text-base-100 leading-normal mt-2 max-w-[600px] mx-auto">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem,
          nostrum alias possimus placeat enim perspiciatis corrupti sunt minus
          quam nulla?
        </p>
      </div>

      <nav className="mt-[30px] flex gap-5 justify-center ">
        <button onClick={handleChange} data-view="members" className="text-sm border-2 border-base-100  text-base-100 rounded-full py-[5px] px-[20px]">
          Members
        </button>
        <button onClick={handleChange} data-view="fixtures" className="text-sm  text-base-100 rounded-full py-[5px] px-[20px]">
          Fixtures
        </button>
        <button onClick={handleChange} data-view="stats" className="text-sm  text-base-100 rounded-full py-[5px] px-[20px]">
          Statistics
        </button>
      </nav>
 
      <div className="mt-[30px]">
        {view == "members" && <Members />}
        {view == "fixtures" && <Fixtures />}
        {view == "stats" && <Stats />}
      </div>
    </div>
  );
}
