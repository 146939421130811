import React from "react";
import logo from "../../../assets/images/logo-text.png";

const Button = ({ text }) => {};

export default function Nav() {
  return (
    <nav className="h-[10vh] bg-lime-500 lg:max-h-[80px] navbar flex justify-between md:px-[40px] fixed top-0 left-0 right-0 shadow-xl z-[999]">
      <span>
        <img src={logo} alt="" />
      </span>

      <div className=" gap-[5px] lg:gap-[20px] justify-center hidden md:flex">
        <a
          href=""
          className=" text-sm bg-lime-950 text-lime-500 px-[20px] py-[5px] rounded-full font-bold"
        >
          Home
        </a>
        <a
          href=""
          className=" text-sm text-lime-950 font-bold px-[20px] py-[5px] rounded-full"
        >
          About Us
        </a>
        <a
          href=""
          className=" text-sm text-lime-950 font-bold px-[20px] py-[5px] rounded-full"
        >
          Solutions
        </a>
        <a
          href=""
          className=" text-sm text-lime-950 font-bold px-[20px] py-[5px] rounded-full md:hidden lg:inline"
        >
          Contact Us
        </a>
      </div>

      <div className="flex gap-[20px] justify-end">
        {/* <a href="" className="text-sm text-white bg-purple font-bold px-[20px] py-[5px] rounded-full">Create an account</a> */}
        <a href="" className="text-sm text-lime-950  border-lime-950  border-2 first-line:font-bold px-[20px] py-[5px] rounded-full">Login</a>

        {/* <label className="input input-bordered flex items-center gap-2 rounded-full  bg-transparent border-2 border-base-100">
          <input
            type="text"
            className="grow text-sm text-lime-950 placeholder-lime-950 focus:outline-none"
            placeholder="Search competitions"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            className="h-4 w-4 opacity-70 stroke-lime-950 fill-lime-950 "
          >
            <path
              fillRule="evenodd"
              d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
              clipRule="evenodd"
            />
          </svg>
        </label> */}
      </div>
    </nav>
  );
}
