import React from "react";

const Award =()=>{
    return (
        <div className="lg:flex justify-center h-full mb-[30px] lg:mb-[40px]">

            <div className="lg:w-[40%]  self-center  p-[20px] lg:px-[20px] lg:py-0">
                <h1 className=" text-2xl lg:text-5xl font-extrabold  text-base-100 text-center mb-2">
                Golden Boot 
                </h1>
                <p className="text-center  text-base-100 max-w-[600px] mx-auto text-sm lg:text-base">
                    Given to the player who scores the most goals in the season.  </p>


            </div>
            <div className="lg:w-[60%]  self-center h-full flex shadow-lg">
                <img className="w-[150px] md:w-[200px] aspect-square" src="https://static.bongda24h.vn/medias/original/2024/10/17/10-marcus-rashford-1710092249.jpg" alt="" />
                <div className="p-[20px]">
                    <p className="text-base-100 text-sm">Manchester United</p>
                    <p className="text-base-100 text-xl font-bold">Marcus</p>
                    <p className="text-base-100 text-2xl font-bold"> Rashford</p>
                    <p className="text-base-100 text-3xl md:text-5xl font-black">10</p>
                </div>
            </div>

        </div>
    )
}

export default function Awards() {
    const awards = ["","","","",""]
  return (
    <div className="max-w-[1200px] mx-auto lg:flex  ">

      <div className="  lg:w-[26%] mt-[50px] md:mt-[80px] px-[20px] py-[0px] ">
        <h1 className="text-2xl font-extrabold  text-base-100 text-center mb-3">
          Award Categories
        </h1>
        <ul className="menu rounded-lg w-full hidden lg:inline-block">
          <li className="rounded-lg flex flex-row py-2 justify-between bg-base-100 text-white">
            {" "}
            <a>Individual Player Awards </a> <span>{">"}</span>{" "}
          </li>
          <li className="text-base-100 flex flex-row py-2 justify-between">
            <a>Manager Awards</a> <span>{">"}</span>{" "}
          </li>
          <li className="text-base-100 flex flex-row py-2 justify-between">
            <a>Goal of the Season</a> <span>{">"}</span>{" "}
          </li>
          
          <li className="text-base-100 flex flex-row py-2 justify-between">
            <a>Team Awards</a> <span>{">"}</span>{" "}
          </li>
        </ul>
      </div>


      <div className="   lg:w-[74%] md:mt-[80px] overflow-y-scroll h-full lg:max-h-[550px] lg:border lg:border-gray-200 lg:p-4" >

        {
            awards.map(award => <Award /> )
        }

        
        
        {/* <div className="flex justify-center h-full">

            <div className="w-[40%]  self-center">
                <h1 className="text-2xl font-extrabold  text-base-100 text-center mb-2">
                Golden Boot 
                </h1>
                <p className="text-center text-base-100 max-w-[600px] mx-auto">
                Playmaker of the Season </p>

            </div>
            <div className="w-[60%] border border-black self-center h-full"></div>

        </div>
        <div className="flex justify-center h-full">

            <div className="w-[40%]  self-center">
                <h1 className="text-2xl font-extrabold  text-base-100 text-center mb-2">
                Playmaker of the Season
                </h1>
                <p className="text-center text-base-100 max-w-[600px] mx-auto">
                 Awarded to the player with the most assists over the season. </p>

            </div>
            <div className="w-[60%] border border-black self-center h-full "></div>

        </div>
        <div className="flex justify-center h-full">

            <div className="w-[40%]  self-center">
                <h1 className="text-2xl font-extrabold  text-base-100 text-center mb-2">
                Golden Glove
                </h1>
                <p className="text-center text-base-100 max-w-[600px] mx-auto">
                Awarded to the goalkeeper with the most clean sheets. </p>

            </div>
            <div className="w-[60%] border border-black self-center h-full "></div>

        </div>

        <div className="flex justify-center h-full">

            <div className="w-[40%]  self-center">
                <h1 className="text-2xl font-extrabold  text-base-100 text-center mb-2">
                Player of the Season
                </h1>
                <p className="text-center text-base-100 max-w-[600px] mx-auto">
                Awarded to the most
        outstanding player of the season, as voted by a combination of fans, the
        Premier League captains, and a panel of football experts.</p>

            </div>
            <div className="w-[60%] border border-black self-center h-full "></div>

        </div> */}



      </div>
    </div>
  );
}
