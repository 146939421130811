import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const dataSlice = createSlice({
  // Authentication state
  name: "data",
  initialState: {},
  reducers: {
    setDataLoading: (state, action) => {
      return {
        ...state,
        dataLoading: action.payload,
      };
    },
    logTimeSpent: (state, action) => {
      
      const {viewer_key,time_spent,page,base_url,method,id}=  action.payload

      // console.log(action.payload)
      
      
      const requestOptions = {
        headers: {
          viewer_key:viewer_key
        },
      };
      
      fetch(`${base_url}api/log_viewer_time.php?page=${page}&time=${time_spent}&method=${method}&id=${id}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        
        // console.log(data)
      })
        .catch((err) => {
          // console.log(err)
          
        });
    },
  },
});

export const { setDataLoading,logTimeSpent} = dataSlice.actions;
export default dataSlice.reducer;
