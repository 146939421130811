import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createBracket } from "bracketry";
import { useLocation } from "react-router-dom";
import Table from "./LeagueTable";
import Bracket from "./Bracket";

const Mixed = () => {
  const base_url = useSelector((state) => state.config.base_url);
  let viewer_key = useSelector((state) => state.config.viewer_key);
  const config = useSelector((state) => state.config);
  const location = useLocation();

  const [view, setView] = useState("groups");

  const [groups, setGroups] = useState(["", "", "", ""]);

  const [teams, setTeams] = useState([
    {
      team_name: "name",
      points: "9",
      matches_played: "4",
      matches_won: "3",
      matches_lost: "1",
      matches_drawn: "0",
      goals_for: "5",
      goals_against: "2",
      goal_difference: "3",
    },
    {
      team_name: "name",
      points: "9",
      matches_played: "4",
      matches_won: "3",
      matches_lost: "1",
      matches_drawn: "0",
      goals_for: "5",
      goals_against: "2",
      goal_difference: "3",
    },
    {
      team_name: "name",
      points: "9",
      matches_played: "4",
      matches_won: "3",
      matches_lost: "1",
      matches_drawn: "0",
      goals_for: "5",
      goals_against: "2",
      goal_difference: "3",
    },
    {
      team_name: "name",
      points: "9",
      matches_played: "4",
      matches_won: "3",
      matches_lost: "1",
      matches_drawn: "0",
      goals_for: "5",
      goals_against: "2",
      goal_difference: "3",
    },
    {
      team_name: "name",
      points: "9",
      matches_played: "4",
      matches_won: "3",
      matches_lost: "1",
      matches_drawn: "0",
      goals_for: "5",
      goals_against: "2",
      goal_difference: "3",
    },
  ]);

  const requestOptions = {
    headers: {
      viewer_key: viewer_key,
    },
  };

  useEffect(() => {
    let path = location.pathname.split("/");
    let id = path[1];

    fetch(`${base_url}/api/get_groups.php?id=${config.season}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status) {
          setGroups(data.groups);
        }
      })
      .catch((err) => {
        alert("Error getting groups");
        // navigate("/");
      });
  }, []);

  const handleViewChange = (view) => {
    setView(view);
  };

  return (
    <div>
      <div className="flex gap-3">
        <button
          className={` border-2 border-base-100 p-1 rounded-full px-5 text-base-100  ${
            view == "groups" ? "text-white bg-base-100" : ""
          } me-2`}
          onClick={() => handleViewChange("groups")}
        >
          Groups
        </button>
        <button
          className={` border-2 border-base-100 p-1 rounded-full px-5 text-base-100 ${
            view == "bracket" ? "text-white bg-base-100" : ""
          } me-2`}
          onClick={() => handleViewChange("bracket")}
        >
          Bracket
        </button>
      </div>

      {view == "groups" && (
        <div className="mt-[50px] gy-3 gx-3">
          {/* <h5>Group Stage</h5> */}

          <div className="grid grid-cols-2 gap-5">
            {groups.map((group, index) => {
              return (
                <div key={index}>
                  <div className="border p-md-3">
                    <h6 className="text-center p-3 bg-dark text-light text-base-100 font-pilat font-bold">
                      {group.name}
                    </h6>
                    <div className="overflow-scroll">
                      {group.table_data && (
                        <Table teams={group.table_data} mixed={true} />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {view == "bracket" && <Bracket />}
    </div>
  );
};

export default Mixed;


