import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";

const HomeGoal = ({ data }) => {
  return (
    <div className="flex  flex-col justify-end gap-0 mt-2">
      <div className="flex justify-end gap-2">
        <p className="flex self-center text-base-100 font-bold text-xs md:text-base">
          00'
        </p>
        <img
          className=" w-[16px] md:w-[25px] aspect-square rounded-full bg-base-100 self-center"
          src=""
          alt=""
        />
      </div>

      <p className=" text-sm md:text-md self-center text-base-100 text-end w-full font-bold">
        {data.player}
      </p>
      <p className=" self-center text-base-100 text-end w-full text-xs md:text-sm ">
        {data.assist.split("_").join(" ")}
      </p>
    </div>
  );
};

const AwayGoal = ({ data }) => {
  return (
    <div className="flex  flex-col justify-end gap-0 mt-2">
      <div className="flex gap-3">
        <img
          className=" w-[16px] md:w-[25px] aspect-square rounded-full bg-base-100 self-center"
          src=""
          alt=""
        />
        <p className="flex self-center text-base-100 font-bold text-xs md:text-base">
          00'
        </p>
      </div>
      <p className=" text-sm md:text-md self-center text-base-100 text-start w-full font-bold">
        {data.player}
      </p>
      <p className=" self-center text-base-100 text-start w-full text-xs md:text-sm mt-1">
        {data.assist.split("_").join(" ")}
      </p>
    </div>
  );
};

const Stat = ({ stat }) => {
  const [homePercentage, setHomePercentage] = useState(0);
  const [awayPercentage, setAwayPercentage] = useState(0);

  useEffect(() => {
    const total = parseInt(stat.data.home + stat.data.away);

    if (total > 0) {
      setHomePercentage((100 / total) * stat.data.home);
      setAwayPercentage((100 / total) * stat.data.away);
    }
  }, []);

  return (
    <div className="mt-[16px] max-w-[800px] mx-auto">
      <div className="flex justify-between mb-2">
        <p className="text-xs md:text-base text-base-100">{stat.data.home}</p>
        <p className="text-xs md:text-base text-base-100">
          {stat.name.split("_").join(" ")}
        </p>
        <p className="text-xs md:text-base text-base-100">{stat.data.away}</p>
      </div>
      <div className="grid grid-cols-2 gap-1 bg-gray-100">
        <div className=" flex justify-end">
          <div className={`py-1 bg-base-100 w-[${homePercentage}%]`}></div>
        </div>
        <div className=" flex justify-start">
          <div className={`py-1 bg-base-100 w-[${awayPercentage}%]`}></div>
        </div>
      </div>
    </div>
  );
};

const MOTMCard = () => {
  return (
    <div className="border border-black  w-[230px] p-3 rounded-lg relative self-center">
      <img src="" className="w-full aspect-[1/.7] border" alt="" />
      <img
        src=""
        className="w-[40px] shadow-lg aspect-square absolute top-5 right-5 border rounded-full"
        alt=""
      />

      <div className="">
        <p className=" text-base-100 mt-2  text-center grid">
          {" "}
          <span className="text-sm">Seye</span> <span>Oluwatobi</span>
        </p>
      </div>
      <div className="flex">
        <button className=" px-[20px] py-[5px] bg-base-100 mt-2 rounded-full mx-auto">
          Vote
        </button>
      </div>
    </div>
  );
};

export default function FixtureDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const base_url = useSelector((state) => state.config.base_url);
  const config = useSelector((state) => state.config);
  let viewer_key = useSelector((state) => state.config.viewer_key);

  let path = location.pathname.split("/");
  let url = path[1];
  let id = path[3];

  const motm = ["", "", "", ""];
  const goals = ["", "", ""];
  const awayGoals = ["", ""];

  const [homeTeamScore, setHomeTeamScore] = useState(0);
  const [awayTeamScore, setAwayTeamScore] = useState(0);
  const [homeTeam, setHomeTeam] = useState(false);
  const [awayTeam, setAwayTeam] = useState(false);

  const [homeTeamGoals, setHomeTeamGoals] = useState([]);
  const [awayTeamGoals, setAwayTeamGoals] = useState([]);

  const [statKeys, setStatKeys] = useState([]);
  const [stats, setStats] = useState({});

  useEffect(() => {
    const requestOptions = {
      headers: {
        viewer_key: viewer_key,
      },
    };

    fetch(
      `${base_url}api/get_fixture_details_mct.php?channel=mct&id=${id}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((json) => {
        const { status, data } = json;

        if (status) {
          setHomeTeamScore(json.data.scoreline.home);
          setAwayTeamScore(json.data.scoreline.away);
          setHomeTeam(json.data.home_team);
          setAwayTeam(json.data.away_team);
          setHomeTeamGoals(json.data.home_team.goals);
          setAwayTeamGoals(json.data.away_team.goals);

          let statKeys = Object.keys(json.data.stats);
          let arr = [];

          statKeys.forEach((key) => {
            arr.push({ name: key, data: json.data.stats[key] });
          });

          setStatKeys(arr);
        } else {
          alert("Error loading fixture data");
          // history.back()
        }
      })
      .catch((err) => {
        console.log(err);
        // handle fixture loading error
      });
  }, []);

  return (
    <div className="mt-[50px] md:mt-[80px] max-w-[1200px] mx-auto ">
      <h1 className="text-2xl font-extrabold mb-[20px] text-base-100 text-center">
        Fixture Details
      </h1>

      <div className=" flex w-full">
        <div className="bg-gray-100 h-[60px]  w-full text-sm md:text-xl text-base-100 font-bold flex relative">
          <img
            src={`${base_url}uploads/${homeTeam.logo}`}
            className=" md:w-[100px] aspect-square absolute top-[-20px] left-[-10px] rounded-full bg-white border shadow-lg"
            alt=""
          />
          <span className="self-center md:ms-[90px]  md:px-[20px]">
            {homeTeam && homeTeam.name}
          </span>
        </div>
        <div className="w-[400px] bg-base-100 h-[80px] md:h-[100px] rounded-b-lg flex flex-col gap-1 justify-center">
          <div className="text-2xl md:text-4xl text-center font-bold align-center text-[#eec488] ">
            {homeTeamScore} - {awayTeamScore}
          </div>
          <div className="text-center align-center text-xs md:text-base text-[#eec488]">
            Full Time
          </div>
        </div>
        <div className="bg-gray-100 h-[60px]  w-full text-sm md:text-xl text-base-100 font-bold flex relative">
          <span className="ms-auto md:me-[90px] self-center md:px-[20px]">
            {awayTeam && awayTeam.name}
          </span>
          <img
            src={`${base_url}uploads/${awayTeam.logo}`}
            className="md:w-[100px] aspect-square absolute top-[-20px] right-[-10px] rounded-full bg-white border shadow-lg"
            alt=""
          />
        </div>
      </div>

      <div className="w-full   grid grid-cols-2 gap-[25px] md:gap-[220px] mt-[20px] md:mt-[-30px]">
        <div>
          {homeTeamGoals.map((goal, index) => (
            <HomeGoal data={goal} key={index} />
          ))}
        </div>
        <div>
          {awayTeamGoals.map((goal, index) => (
            <AwayGoal data={goal} key={index} />
          ))}
        </div>
      </div>

      <h1 className="text-xl md:text-2xl font-extrabold mt-[50px] text-base-100 text-center">
        Game Statistics{" "}
      </h1>

      {statKeys.map((stat, index) => (
        <Stat key={index} stat={stat} />
      ))}

      <div className="mt-[80px]">
        <h1 className="text-2xl font-extrabold  text-base-100 text-center">
          Vote Your <br /> Player of The Match{" "}
        </h1>

        <p className="text-center text-base-100 mt-2">
          Award Catergory Sponsored by InDrive
        </p>

        {/* <p className="text-center text-sm text-base-100 mt-2">Indrive gives you the best prices on all trips</p> */}

        <div className="flex flex-col md:flex-row mt-[40px] justify-center  gap-[20px]">
          {motm.map((m, i) => (
            <MOTMCard key={i} />
          ))}
        </div>
      </div>

      <div className="flex mt-[100px]">
        <a href="" className="btn mx-auto rounded-full px-[30px]">
          View award Categories
        </a>
      </div>
    </div>
  );
}
